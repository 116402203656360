<template>
    <a-card>
        <a-page-header
            title="修改"
        />

        <div class="content-box">
            <div class="video">
                <img :src="dataForm.adPosterUrl" alt="视频封面" @click="handlePreviewOpen(dataForm.hjkAdVideoId)" />
            </div>
            <div class="content">
                <a-form-model
                    ref="dataForm"
                    :model="dataForm"
                    :rules='rules'
                    v-bind='layout'
                >
                    <a-form-model-item label="视频标题">
                        {{ dataForm.videoTitle || '-' }}
                    </a-form-model-item>
                    <a-form-model-item label="视频ID">
                        {{ dataForm.hjkAdVideoId || '-' }}
                    </a-form-model-item>
                    <a-form-model-item label="案例适用范围" prop='videoScope'>
                        <a-radio-group v-model="dataForm.videoScope" @change="onChangeScope">
                            <a-radio :value="1">车系</a-radio>
                            <a-radio :value="2">品牌</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="品牌车系" prop="principalId" style="margin-bottom: 0">
                        <a-select
                            v-model="dataForm.principalId"
                            placeholder="请选择品牌"
                            show-search
                            option-filter-prop="children"
                            style="width: 100%;"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                                :name="item.principal_name"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label=" " :colon="false" prop="carSeriesId" v-if="dataForm.videoScope != 2">
                        <a-select
                            v-model="dataForm.carSeriesId"
                            placeholder="请选择车系"
                            show-search
                            option-filter-prop="children"
                            style="width: 100%;"
                            :disabled="!dataForm.principalId"
                            @change="onChangeCarSeries"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                                :name="item.name"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="导拍模板" style="margin-top: 24px;">
                        <a-select
                            v-model="dataForm.guideTemplateId"
                            placeholder="请选择导拍模板"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @search="getTemplateList"
                        >
                            <a-select-option
                                v-for="item in moduleList"
                                :key="item.id"
                            >
                                {{ item.guideshootName }} (ID：{{ item.id }})
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label=" " :colon="false">
                        <a-space>
                            <base-button
                                :title="'取消'"
                                @onClickBtn="handleBack"
                            ></base-button>
                            <base-button
                                :type="'primary'"
                                :title="'提交'"
                                :loading="isSubmit"
                                :disabled="isSubmit"
                                @onClickBtn="handleSubmit"
                            ></base-button>
                        </a-space>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
    </a-card>
</template>

<script>
import moment from 'moment'
import debounce from 'lodash/debounce'

export default {
    data() {
        this.getTemplateList = debounce(this.getTemplateList, 1000)

        let checkCarSeriesId = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择车系'))
            } else {
                callback();
            }
        }

        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            dataForm: {
                videoScope: 1,
                principalId: undefined,
                carSeriesId: undefined,
            },
            rules: {
                videoScope: [
                    { required: true, message: '请选择组件适用范围', trigger: 'change' }
                ],
                principalId: [
                    { required: true, message: '请选择品牌', trigger: 'change' }
                ],
                carSeriesId: [
                    { validator: checkCarSeriesId, trigger: 'change' }
                ],
            },
            principalList: [],
            seriesList: [],
            moduleList: [],
            isSubmit: false,
            date_format: 'YYYY-MM-DD',
        }
    },
    created() {
        this.id != 0 ? this.getDetail() : ''

        this.getPrincipalList()
        this.getTemplateList()
    },
    methods: {
        handlePreviewOpen(id) {
            this.$api.core.materialManage.getUrlByHjkAdVideoId(id).then((res) => {
                if(res.code == 200) {
                    window.open(res.data.playUrl, '_blank', 'noreferrer=yes')
                } else {
                    this.$message.error('获取视频地址失败')
                }
            })
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangeScope(e) {
            this.dataForm.carSeriesId = undefined
            if(e.target.value == 1) {
                this.getPrincipalSeriesList(this.dataForm.principalId)
            }
        },
        onChangePrincipal(id, option) {
            this.dataForm.carSeriesId = undefined
            this.dataForm.carSeriesName = ''
            if(id) {
                this.dataForm.principalName = option.data.attrs.name
            } else {
                this.dataForm.principalName = ''
            }
            this.getPrincipalSeriesList(id)
        },
        onChangeCarSeries(data, option) {
            this.dataForm.carSeriesName = option.data.attrs.name
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: [id],
                status: [1, 4]
            }
            
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        //获取导拍模板列表
        getTemplateList(guideshootName) {
            let params = {
                guideshootName,
                size: 1000,
                page: 1
            }
            this.$api.core.materialManage.getTemplateList(params).then((res) => {
                if(res.code == 200) {
                    this.moduleList = res.data.list
                } else {
                    this.moduleList = []
                    console.error(`获取模板列表失败${res}`)
                }
            })
        },
        //获取详情
        getDetail() {
            this.$api.core.pphtManage.getVideoCaseDetailById({id: this.id}).then((res) => {
                if(res.code == 200) {
                    let _data = res.data
                    if(_data.videoScope == 1) {
                        this.getPrincipalSeriesList(_data.principalId)
                    }
                    if(_data.guideTemplateName) {
                        this.getTemplateList(_data.guideTemplateName)
                    }
                    _data.videoScope = _data.videoScope || 1

                    this.$set(this, 'dataForm', _data)
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        //取消
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/videoCaseManages'
                    })
                }
            })
        },
        //提交
        handleSubmit() {
            this.$refs.dataForm.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true

                    console.log(this.dataForm)

                    this.$api.core.pphtManage.submitVideoCaseDetail( Object.assign(this.dataForm, { id: this.id }) ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success(`提交成功`)
                            this.$router.push({
                                path: '/videoCaseManages'
                            })
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
/deep/ .ant-form-item-children {
    position: relative;
}
.content-box {
    display: flex;
    .video {
        width: 250px;
        img {
            width: 100%;
        }
    }
    .content {
        flex: 1;
    }
}
</style>